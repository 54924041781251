import React from "react";

export default function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_2984_6706)">
        <path
          d="M-0.499496 4C-0.499496 3.46957 -0.288783 2.96086 0.08629 2.58579C0.461363 2.21071 0.970071 2 1.5005 2H13.5005C14.0309 2 14.5396 2.21071 14.9147 2.58579C15.2898 2.96086 15.5005 3.46957 15.5005 4V12C15.5005 12.5304 15.2898 13.0391 14.9147 13.4142C14.5396 13.7893 14.0309 14 13.5005 14H1.5005C0.970071 14 0.461363 13.7893 0.08629 13.4142C-0.288783 13.0391 -0.499496 12.5304 -0.499496 12V4ZM1.5005 3C1.23529 3 0.980933 3.10536 0.793397 3.29289C0.60586 3.48043 0.500504 3.73478 0.500504 4V4.217L7.5005 8.417L14.5005 4.217V4C14.5005 3.73478 14.3951 3.48043 14.2076 3.29289C14.0201 3.10536 13.7657 3 13.5005 3H1.5005ZM14.5005 5.383L9.7425 8.238L14.5005 11.114V5.384V5.383ZM14.4665 12.261L8.7715 8.82L7.5005 9.583L6.2285 8.82L0.534504 12.26C0.591702 12.4724 0.717352 12.6601 0.891991 12.7938C1.06663 12.9276 1.28051 13.0001 1.5005 13H13.5005C13.7204 13.0001 13.9341 12.9278 14.1088 12.7942C14.2834 12.6607 14.4091 12.4732 14.4665 12.261ZM0.500504 11.114L5.2585 8.238L0.500504 5.383V11.113V11.114Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2984_6706">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00050354)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
