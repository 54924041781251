import React from "react";

export default function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_2984_6721)">
        <path
          d="M16.002 8.05024C16.002 3.60424 12.42 0.000244141 8.002 0.000244141C3.582 0.00124414 0 3.60424 0 8.05124C0 12.0682 2.926 15.3982 6.75 16.0022V10.3772H4.72V8.05124H6.752V6.27624C6.752 4.25924 7.947 3.14524 9.774 3.14524C10.65 3.14524 11.565 3.30224 11.565 3.30224V5.28224H10.556C9.563 5.28224 9.253 5.90324 9.253 6.54024V8.05024H11.471L11.117 10.3762H9.252V16.0012C13.076 15.3972 16.002 12.0672 16.002 8.05024Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2984_6721">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
