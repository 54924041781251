import React from "react";

export default function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_2984_6724)">
        <path
          d="M5.026 14.0009C11.064 14.0009 14.367 8.99794 14.367 4.66694C14.367 4.52694 14.367 4.38494 14.361 4.24494C15.0041 3.77942 15.5591 3.20309 16 2.54294C15.3993 2.80779 14.7628 2.98234 14.111 3.06094C14.7975 2.65057 15.3117 2.00491 15.558 1.24394C14.913 1.62606 14.207 1.89431 13.471 2.03694C12.9762 1.50996 12.3214 1.16085 11.6081 1.04369C10.8948 0.926535 10.1627 1.04786 9.52534 1.38887C8.88796 1.72989 8.38081 2.27157 8.08245 2.93C7.78409 3.58843 7.71118 4.32687 7.875 5.03094C6.56974 4.96549 5.29282 4.62641 4.12704 4.03568C2.96127 3.44495 1.93268 2.61578 1.108 1.60194C0.68934 2.32502 0.561574 3.18034 0.750646 3.9942C0.939718 4.80807 1.43145 5.51947 2.126 5.98394C1.60554 5.96625 1.09652 5.8265 0.64 5.57594V5.62094C0.640897 6.37842 0.903307 7.11236 1.38287 7.6987C1.86244 8.28504 2.52975 8.68781 3.272 8.83894C2.99026 8.91655 2.69923 8.95524 2.407 8.95394C2.20098 8.95456 1.99538 8.93547 1.793 8.89694C2.00279 9.54899 2.41127 10.1191 2.96125 10.5274C3.51122 10.9357 4.17513 11.1618 4.86 11.1739C3.69656 12.0877 2.2594 12.5833 0.78 12.5809C0.519321 12.582 0.258823 12.567 0 12.5359C1.50151 13.4932 3.2453 14.0015 5.026 14.0009Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2984_6724">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
