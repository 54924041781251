import React from "react";

export default function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_2984_6718)">
        <path
          d="M9.051 1.99927H9.14C9.962 2.00227 14.127 2.03227 15.25 2.33427C15.5895 2.42644 15.8989 2.60609 16.1472 2.85528C16.3955 3.10446 16.574 3.41445 16.665 3.75427C16.766 4.13427 16.837 4.63727 16.885 5.15627L16.895 5.26027L16.917 5.52027L16.925 5.62427C16.99 6.53827 16.998 7.39427 16.999 7.58127V7.65627C16.998 7.85027 16.989 8.76427 16.917 9.71627L16.909 9.82127L16.9 9.92527C16.85 10.4973 16.776 11.0653 16.665 11.4833C16.5743 11.8232 16.3958 12.1334 16.1475 12.3826C15.8991 12.6318 15.5896 12.8114 15.25 12.9033C14.09 13.2153 9.681 13.2373 9.07 13.2383H8.928C8.619 13.2383 7.341 13.2323 6.001 13.1863L5.831 13.1803L5.744 13.1763L5.573 13.1693L5.402 13.1623C4.292 13.1133 3.235 13.0343 2.748 12.9023C2.40849 12.8105 2.09907 12.6311 1.85072 12.382C1.60238 12.133 1.42385 11.823 1.333 11.4833C1.222 11.0663 1.148 10.4973 1.098 9.92527L1.09 9.82027L1.082 9.71627C1.03265 9.03867 1.0053 8.35964 1 7.68027L1 7.55727C1.002 7.34227 1.01 6.59927 1.064 5.77927L1.071 5.67627L1.074 5.62427L1.082 5.52027L1.104 5.26027L1.114 5.15627C1.162 4.63727 1.233 4.13327 1.334 3.75427C1.42469 3.41432 1.60316 3.10418 1.85151 2.85495C2.09986 2.60572 2.40937 2.42616 2.749 2.33427C3.236 2.20427 4.293 2.12427 5.403 2.07427L5.573 2.06727L5.745 2.06127L5.831 2.05827L6.002 2.05127C6.95371 2.02064 7.90581 2.00364 8.858 2.00027H9.051V1.99927ZM7.4 5.20927V10.0273L11.557 7.61927L7.4 5.20927Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2984_6718">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
